import React from 'react';
import { navigate } from 'gatsby';
import languageStore from '../../store/languageStore';
import planStore from '../../store/planStore';
import * as EN from '../../i18n/messages/en-US';
import * as DE from '../../i18n/messages/de-DE';
import * as constants from '../../app.constant';
import { observer } from 'mobx-react';
import { LOCALES } from '../../i18n';
import { getModifiedPrice } from '../../service/prod-config';
import translate from '../../i18n/translate';
import appleIcon from "../../assets/images/appleIcon.png"

const Products = observer((props) => {
  const getText = (keyName) => {
    const keyArr = keyName.split('.');
    if (keyArr.length > 1) {
      if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
        return EN[keyArr[0]][keyArr[1]];
      } else {
        return DE[keyArr[0]][keyArr[1]]
      }
    }
  }

  const gotoProductDetails = (item) => {
    planStore.updateProductInfo(item);
    navigate(constants.ROUTES.PRODUCT_INFO);
  }

  const mode = planStore.mode;
  console.log("Mode in products components : {}", mode);

  return (
    <>
      <div className="row">
        {planStore.displayProductList &&
          planStore.displayProductList.map(item => {
            if (mode === 0 && (item.productId === "ATDREIAUSADLDEW101" || item.productId === "ATDREIAUSADLDEWTHEFT101")){
              console.log("skip displaying F&F Products : "+item.productId);
            } else {
                return (
                  <div key={item.productId} className="col-4 product-details-gap" >
                    {item.plans && item.plans.actualProductPrice && <div className="product-card landing-page-product-card">
                      {item.isPopular && <div className="most-comprehensive">{translate("home.products.popular")}</div>}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h2 className="productName" dangerouslySetInnerHTML={{ __html: getText(item.productName) }}></h2>
                      {constants.ACS_PRODUCT_IDS.includes(item.productId) &&(<img src={appleIcon} alt="Product" style={{ width: '100px', height: 'auto', marginLeft: '10px' }} />)}
                      </div>
                      <div className="productPrice">{item.plans && item.plans.actualProductPrice && getModifiedPrice(item.plans.actualProductPrice)}{item.plans && item.plans.actualProductPrice && item.currencySymbol}</div>
                      {item.plans && item.plans.actualProductPrice && <div className="per-month">{item.tenureToShow}</div>}
                      <div className="button-wrapper"><button disabled={!props.canCustomerProceed} type="button" className="btn btn-cyan" onClick={() => gotoProductDetails(item)}><span>{translate('home.products.cta.learnmore')}</span></button></div>
                      <ul className="coverageType">
                        {item.featureList && item.featureList.map(featureItem => (
                          <li key={featureItem}>{getText(featureItem)}</li>
                        ))}
                      </ul>
                    </div>}
                  </div>
                )
              }
          })}
      </div>
    </>
  )
})

export default Products;